import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_WebSocketManager = _resolveComponent("WebSocketManager")!
  const _component_TabActiveManager = _resolveComponent("TabActiveManager")!
  const _component_RouteManager = _resolveComponent("RouteManager")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_WebSocketManager),
    _createVNode(_component_TabActiveManager),
    _createVNode(_component_RouteManager)
  ], 64))
}