import { ApiHandler } from "./ApiHandler";
import { ChatsApi } from "./methods/ChatsApi";
import { ContactsApi } from "./methods/ContactsApi";
import { DocumentsApi } from "./methods/DocumentsApi";
import { MediasApi } from "./methods/MesiasApi";
import { MessageApi } from "./methods/MessageApi";
import { SearchApi } from "./methods/SearchApi";
import { SmsApi } from "./methods/SmsApi";
import { UserApi } from "./methods/UserApi";
import { UsernameApi } from "./methods/UsernameApi";

export const Api = {
  chats: ChatsApi,
  sms: SmsApi,
  user: UserApi,
  username: UsernameApi,
  contacts: ContactsApi,
  documents: DocumentsApi,
  messages: MessageApi,
  medias: MediasApi,
  search: SearchApi,

  get: ApiHandler.get,
  post: ApiHandler.post,
};
