import { Chat } from "@/shared/interfaces/Chat";
import { ApiHandler } from "@/shared/api/ApiHandler";
import { Cookies } from "@/shared/helpers/Cookies";

export async function chatsGetPaged(
  folderId: number,
  page: number
): Promise<{ chats: Chat[]; page_count: number }> {
  const userId = +Cookies.get("userId");

  return await ApiHandler.get(
    `user/${userId}/chats/chats/${folderId}/0/0/${page}`
  );
}
