
import { defineComponent } from "vue-demi";
import { Cookies } from "@/shared/helpers/Cookies";
import { ContactInfo } from "@/shared/interfaces/ContactInfo";
import { User } from "@/shared/interfaces/User";
import { Message } from "@/shared/interfaces/Message";
import { PlatformStore } from "@/store/modules/Platform/PlatformStore";
import { PrincipalStore } from "@/store/modules/Principal/PrincipalStore";
import { principalRefresh } from "@/store/modules/Principal/Methods/principalRefresh";
import { ChatsStore } from "@/store/modules/Chats/ChatsStore";
import { chatsRefresh } from "@/store/modules/Chats/Methods/chatsRefresh";
import { chatsUpdateUserProfile } from "@/store/modules/Chats/Methods/chatsUpdateUserProfile";
import { chatsUpdateMessageStatus } from "@/store/modules/Chats/Methods/chatsUpdateMessageStatus";
import { chatsSetUserOnline } from "@/store/modules/Chats/Methods/chatsSetUserOnline";
import { chatsMarkMessagesAsRead } from "@/store/modules/Chats/Methods/chatsMarkMessagesAsRead";
import { chatsAddMessageToChat } from "@/store/modules/Chats/Methods/chatsAddMessageToChat";
import { contactsSetUserOnline } from "@/store/modules/Contacts/Methods/contactsSetUserOnline";
import { contactsUpdateProfile } from "@/store/modules/Contacts/Methods/contactsUpdateProfile";
import { ContactsStore } from "@/store/modules/Contacts/ContactsStore";
import { Api } from "../api/Api";
import { Folder } from "../interfaces/Folder";
import { FoldersStore } from "@/store/modules/Folders/FoldersStore";
import { chatsGetAll } from "@/store/modules/Chats/Methods/chatsGetAll";

export interface WebSocketMessage {
  status: number;
  message: {
    answer_to: Message;
    type: "update" | string;
    topic:
      | "user_online"
      | "receive_messages"
      | "contact_notification"
      | "chat_notification"
      | "profile_update_notification"
      | "messages_status_update"
      | "mark_messages_as_read"
      | "change_auth_user_id"
      | "groups_notification"
      | "folders_notification";
    action_type: string;
    chat_id: number;
    chat: any;
    group_id: number;
    dt_last_online: string;
    contact: ContactInfo;
    user_id: number;
    online: number;
    user: User;
    message_id: number;
    status: number;
    folders: Folder[];
    message: Message;
  };
}

export default defineComponent({
  components: {},
  methods: {
    connectNotifications() {
      // Уведомления не поддерживаются, расходимся.
      if (!("Notification" in window)) {
        return;
      }

      // Уведомления уже подключены, ничего делать не нужно.
      if (Notification.permission === "granted") {
        return;
      }

      // Уведомления еще не разрешались, спрашиваем о них.
      if (Notification.permission !== "denied") {
        Notification.requestPermission();
      }
    },
    onContactNotification(contact: ContactInfo) {
      this.chatsStore.dispatch(async (state) => {
        const chat = state.chats.filter(
          (s) => s.contact && s.contact.id == contact.id
        )[0];

        if (chat) {
          chat.contact = contact;
        }
      });

      this.contactsStore.dispatch(async (state) => {
        const con = state.contacts.filter(
          (s) => s.contact && s.contact.id == contact.id
        )[0];

        if (con) {
          con.contact = contact;
        }
      });
    },
    async onSocketMessage(event: any) {
      const eventData: WebSocketMessage = JSON.parse(event.data);

      console.log("Получено сообщение из websocket:", eventData);

      if (
        !eventData ||
        !eventData.message ||
        eventData.message.type != "update"
      ) {
        return;
      }

      if (
        eventData.message.topic === "groups_notification" ||
        eventData.message.action_type === "update"
      ) {
        this.chatsStore.dispatch(async (state) => {
          const chat = state.chats.find(
            (s) =>
              s.id === eventData.message?.chat?.id ||
              s.id === eventData.message?.group_id
          );

          if (chat && eventData.message?.chat?.group_info) {
            chat.group_info = eventData.message.chat.group_info;
          }
        });
      }

      if (
        eventData.message.topic === "groups_notification" ||
        eventData.message.action_type === "create"
      ) {
        this.chatsStore.dispatch(async (state) => {
          const chat = state.chats.find(
            (s) =>
              s.id === eventData.message?.chat?.id ||
              s.id === eventData.message?.group_id
          );

          if (!chat) {
            state.chats = [eventData.message.chat, ...state.chats];
          }
        });
      }

      if (eventData.message.action_type == "update_groups_online") {
        const groupId: number = (eventData.message as any).group_id;
        const usersCount: number = (eventData.message as any).users_count;
        const usersOnline: number = (eventData.message as any).users_online;

        this.chatsStore.dispatch(async (state) => {
          const chat = state.chats.filter(
            (s) => s.group_info && s.id == groupId
          )[0];

          if (chat) {
            chat.group_info.users_count = usersCount;
            chat.group_info.users_online = usersOnline;
          }
        });
      }

      if (eventData.message.topic === "folders_notification") {
        const foldersStore = FoldersStore.get().foldersStore;

        foldersStore.dispatch(async (state) => {
          state.folders = eventData.message.folders.sort(
            (a, b) => a.sorting_number - b.sorting_number
          );

          if (state.selectedFolder) {
            state.selectedFolder = state.folders.find(
              (s) => s.id === state.selectedFolder.id
            );
          }
        });
      }

      if (eventData.message.topic == "receive_messages") {
        if (eventData.message.user_id == this.chatsState.userId) {
          return;
        }

        const chatId = eventData.message.chat_id;

        let chat = this.chatsState.chats.filter((s) => s.id == chatId)[0];

        if (!chat) {
          const newChats = await chatsGetAll();

          chat = newChats.filter((s) => s.id == chatId)[0];

          if (!chat) {
            return;
          }

          this.chatsState.chats = [chat, ...this.chatsState.chats];
        }

        const loadMessages = chat.group_info
          ? Api.messages.getFromGroupChat
          : Api.messages.get;

        const response = await loadMessages(
          this.chatsState.userId,
          chatId,
          0,
          1
        );
        const message = response.messages.filter(
          (s) => s.id == eventData.message.message.id
        )[0];

        if (!message) {
          return;
        }

        chatsAddMessageToChat(
          eventData.message.chat_id,
          message,
          eventData.message.user
        );

        const minChatScrollPosition = -700;
        let chatScrollPosition = minChatScrollPosition - 1;

        try {
          chatScrollPosition =
            document.querySelector(".cMessageGroups").scrollTop;
        } catch (e) {}

        if (
          this.platformState.isTabActive &&
          this.chatsState.selectedChat &&
          this.chatsState.selectedChat.id === eventData.message.chat_id &&
          chatScrollPosition > minChatScrollPosition
        ) {
          chatsMarkMessagesAsRead([eventData.message.chat_id]);
        }

        if (
          "Notification" in window &&
          Notification.permission === "granted" &&
          this.principalState.user.is_notifications_active == 1 &&
          this.chatsState.chats.filter(
            (s) => s.user && s.user.id == eventData.message.user.id
          ).length &&
          eventData.message.user.id != this.principalState.user.id
        ) {
          let username =
            eventData.message.user.name + " " + eventData.message.user.surname;

          if (eventData.message.contact && eventData.message.contact.name) {
            username =
              eventData.message.contact.name +
              " " +
              eventData.message.contact.surname;
          }

          const notification = new Notification(username, {
            body: eventData.message.message.text,
          });

          notification.onclick = (event) => {
            event.preventDefault();

            window.open(
              `${window.location.origin}/chats/${eventData.message.chat_id}`,
              "_blank"
            );
          };
        }
      } else if (eventData.message.topic == "user_online") {
        chatsSetUserOnline(
          eventData.message.user_id,
          eventData.message.online,
          eventData.message.dt_last_online
        );
        contactsSetUserOnline(
          eventData.message.user_id,
          eventData.message.online,
          eventData.message.dt_last_online
        );
      } else if (eventData.message.topic == "messages_status_update") {
        chatsUpdateMessageStatus(
          eventData.message.chat_id,
          eventData.message.message_id,
          eventData.message.status
        );
      } else if (eventData.message.topic == "profile_update_notification") {
        chatsUpdateUserProfile(eventData.message.user);
        contactsUpdateProfile(
          eventData.message.user.id,
          eventData.message.user
        );
        principalRefresh();
      } else if (eventData.message.topic == "contact_notification") {
        this.onContactNotification(eventData.message.contact);
      } else if (eventData.message.topic == "chat_notification") {
        chatsRefresh();
      }
    },
    connectToWebSocket() {
      const userId = +Cookies.get("userId");

      if (!userId) {
        return;
      }

      let socket = new WebSocket(`wss://dtchat.dtdesk.ru/ws/${userId}/`);

      socket.onopen = () => {
        if (Cookies.get("authToken")) {
          socket.send(
            JSON.stringify({
              type: "action",
              topic: "set_auth_token",
              auth_token: Cookies.get("authToken"),
            })
          );
        }

        socket.send(
          JSON.stringify({
            type: "action",
            topic: "set_client_platform",
            client_platform: "web",
          })
        );

        socket.send(
          JSON.stringify({
            type: "subscribe",
            topic:
              "user_online,receive_messages,contact_notification,chat_notification,profile_update_notification,messages_status_update,mark_messages_as_read,change_auth_user_id,groups_notification,folders_notification",
          })
        );
      };

      socket.onmessage = this.onSocketMessage;

      socket.onclose = (e: any) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second.",
          e.reason
        );
        setTimeout(() => {
          this.connectToWebSocket();
        }, 1000);
      };

      socket.onerror = (err: any) => {
        console.error(
          "Socket encountered error: ",
          err.message,
          "Closing socket"
        );
        socket.close();
      };
    },
  },
  mounted() {
    this.connectNotifications();

    this.connectToWebSocket();
  },
  setup() {
    const { contactsStore } = ContactsStore.get();
    const { principalState } = PrincipalStore.get();
    const { platformState } = PlatformStore.get();
    const { chatsState, chatsStore } = ChatsStore.get();

    return {
      contactsStore,
      platformState,
      principalState,

      chatsStore,
      chatsState,
    };
  },
});
