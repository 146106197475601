import router, { ViewType } from "@/router";
import { routerAddQueryParam } from "../../Router/Methods/routerAddQueryParam";

export function sidebarPushRightView(view: ViewType) {
  const url = routerAddQueryParam(window.history.state.current, {
    right: view ? ViewType[view] : null,
    m: null,
  });

  if (window.history.state.back == url) {
    router.back();
  } else {
    router.push(url);
  }
}
