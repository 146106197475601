import { Store, useStore } from "vuex";
export class Stores {
  private static _rootStore: Store<any>;

  public static get rootStore(): Store<any> {
    if (!this._rootStore) {
      this._rootStore = useStore();
    }

    return this._rootStore;
  }
}
