import { StoreBase } from "@/store/base/StoreBase";
import { ViewType } from "@/router";

export interface SidebarStoreState {
  leftView: ViewType;
  rightView: ViewType;
}

export class SidebarStore extends StoreBase<SidebarStoreState> {
  public static getNamespace(): string {
    return `sidebar`;
  }

  constructor() {
    super(SidebarStore.getNamespace());
  }

  public static get(): {
    sidebarStore: SidebarStore;
    sidebarState: SidebarStoreState;
  } {
    const namespace = this.getNamespace();

    if (!this._hasModule(namespace)) {
      this._register<SidebarStoreState>(namespace, {
        leftView: null,
        rightView: ViewType.none,
      });
    }

    const store = new SidebarStore();

    return {
      sidebarStore: store,
      sidebarState: store.state,
    };
  }
}
