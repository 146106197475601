import { ContactsStore } from "../ContactsStore";

export async function contactsSetUserOnline(
  userId: number,
  isOnline: number,
  lastOnlineDate: string
) {
  const { contactsStore } = ContactsStore.get();

  contactsStore.dispatch(async (state) => {
    const contact = state.contacts.filter((s) => s.user.id == userId)[0];

    if (contact) {
      contact.user.is_online = isOnline;
      contact.user.dt_last_online = lastOnlineDate;
    }
  });
}
