import { ViewType } from "@/router";
import { SidebarStore } from "../SidebarStore";

export function sidebarSetLeftView(view: ViewType): void {
  const { sidebarStore } = SidebarStore.get();

  sidebarStore.dispatch(async (state) => {
    state.leftView = view;
  });
}
