import { Cookies } from "@/shared/helpers/Cookies";
import { FoldersStore } from "../FoldersStore";
import { Api } from "@/shared/api/Api";
import { Folder } from "@/shared/interfaces/Folder";
import { ApiHandler } from "@/shared/api/ApiHandler";

export async function foldersRefresh(): Promise<Folder[]> {
  const { foldersStore } = FoldersStore.get();

  if (!Cookies.get("userId")) {
    foldersStore.dispatch(async (state) => {
      state.userId = 0;
      state.folders = [];
    });
  } else {
    const userId = +Cookies.get("userId");
    const response = await ApiHandler.get(`user/${userId}/folders/folders`);
    const folders: Folder[] = response.folders;

    foldersStore.dispatch(async (state) => {
      state.userId = userId;
      state.folders = folders.sort(
        (a, b) => a.sorting_number - b.sorting_number
      );

      console.log(folders);
    });
  }

  foldersStore.dispatch(async (state) => {
    state.isLoading = false;
  });

  return foldersStore.state.folders;
}
