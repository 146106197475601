import { Stores } from "../modules/Stores";

export class StoreBase<TState> {
  public namespace: string;

  protected constructor(namespace: string) {
    this.namespace = namespace;
  }

  public get state(): TState {
    return Stores.rootStore!.state[this.namespace];
  }

  public dispatch<T>(doUpdate: (state: TState) => Promise<T>): Promise<T> {
    return Stores.rootStore!.dispatch(`${this.namespace}/updateState`, {
      doUpdate,
    });
  }

  protected static _hasModule(namespace: string) {
    return Stores.rootStore!.hasModule(namespace);
  }

  protected static _register<TState>(namespace: string, state: TState) {
    const rootStore = Stores.rootStore!;

    if (rootStore.hasModule(namespace)) {
      return;
    }

    rootStore.registerModule(namespace, {
      namespaced: true,

      state: (): any => state,

      actions: {
        async updateState(
          { state }: { state: object },
          args: { doUpdate: (state: object) => Promise<any> }
        ) {
          return await args.doUpdate(state);
        },
      },
    });
  }

  protected static _unregister(namespace: string) {
    const rootStore = Stores.rootStore!;

    if (rootStore.hasModule(namespace)) {
      rootStore.unregisterModule(namespace);
    }
  }
}
