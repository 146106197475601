export abstract class LazyModules {
  public static readonly LoginView = () =>
    import(/* webpackChunkName: "login" */ "../views/LoginView/LoginView.vue");
  public static readonly SmsCodeView = () =>
    import(
      /* webpackChunkName: "smsCode" */ "../views/SmsCodeView/SmsCodeView.vue"
    );
  public static readonly RegistrationView = () =>
    import(
      /* webpackChunkName: "register" */ "../views/RegistrationView/RegistrationView.vue"
    );
  public static readonly FirstContactView = () =>
    import(
      /* webpackChunkName: "firstContact" */ "../views/FirstContactView/FirstContactView.vue"
    );
  public static readonly MessengerView = () =>
    import(
      /* webpackChunkName: "messenger" */ "../views/MessengerView/MessengerView.vue"
    );

  public static readonly MessagesView = () =>
    import(
      /* webpackChunkName: "messages" */ "@/views/MessagesView/MessagesView.vue"
    );
  public static readonly ChatListView = () =>
    import(
      /* webpackChunkName: "chatList" */ "@/views/ChatListView/ChatListView.vue"
    );
  public static readonly EditProfileView = () =>
    import(
      /* webpackChunkName: "editProfile" */ "@/views/EditProfileView/EditProfileView.vue"
    );

  public static readonly CreateGroupView = () =>
    import(
      /* webpackChunkName: "createGroup" */ "@/views/CreateGroupView/CreateGroupView.vue"
    );

  public static readonly EditGroupView = () =>
    import(
      /* webpackChunkName: "editGroup" */ "@/views/EditGroupView/EditGroupView.vue"
    );

  public static readonly GroupMembersView = () =>
    import(
      /* webpackChunkName: "groupMembers" */ "@/views/GroupMembersView/GroupMembersView.vue"
    );

  public static readonly GroupMembersSearchView = () =>
    import(
      /* webpackChunkName: "groupMembersSearch" */ "@/views/GroupMembersSearchView/GroupMembersSearchView.vue"
    );

  public static readonly GroupAdminsView = () =>
    import(
      /* webpackChunkName: "groupAdmins" */ "@/views/GroupAdminsView/GroupAdminsView.vue"
    );

  public static readonly AddChatsToGroupView = () =>
    import(
      /* webpackChunkName: "addChatsToGroup" */ "@/views/AddChatsToGroupView/AddChatsToGroupView.vue"
    );

  public static readonly AddAdminsToGroupView = () =>
    import(
      /* webpackChunkName: "addAdminsToGroup" */ "@/views/AddAdminsToGroupView/AddAdminsToGroupView.vue"
    );

  public static readonly GroupPermissionsView = () =>
    import(
      /* webpackChunkName: "groupPermissions" */ "@/views/GroupPermissionsView/GroupPermissionsView.vue"
    );

  public static readonly ContactListView = () =>
    import(
      /* webpackChunkName: "contactList" */ "@/views/ContactListView/ContactListView.vue"
    );
  public static readonly ChatSearchView = () =>
    import(
      /* webpackChunkName: "chatSearch" */ "@/views/ChatSearchView/ChatSearchView.vue"
    );
  public static readonly ContactInfoView = () =>
    import(
      /* webpackChunkName: "contactInfo" */ "@/views/ContactInfoView/ContactInfoView.vue"
    );
  public static readonly ContactEditView = () =>
    import(
      /* webpackChunkName: "contactEdit" */ "@/views/ContactEditView/ContactEditView.vue"
    );
  public static readonly EditFoldersView = () =>
    import(
      /* webpackChunkName: "editFolders" */ "@/views/EditFoldersView/EditFoldersView.vue"
    );

  public static readonly CreateFolderView = () =>
    import(
      /* webpackChunkName: "createFolder" */ "@/views/CreateFolderView/CreateFolderView.vue"
    );

  public static readonly EditFolderView = () =>
    import(
      /* webpackChunkName: "editFolder" */ "@/views/EditFolderView/EditFolderView.vue"
    );

  public static readonly AddChatsToFolderView = () =>
    import(
      /* webpackChunkName: "addChatsToFolder" */ "@/views/AddChatsToFolderView/AddChatsToFolderView.vue"
    );

  public static readonly TestError = () =>
    import(
      /* webpackChunkName: "testError" */ "@/views/TestErrorView/TestErrorView.vue"
    );

  public static readonly LameJs = () =>
    import(/* webpackChunkName: "lamejstmp" */ "lamejstmp");
  public static readonly WaveSurfer = () =>
    import(/* webpackChunkName: "wavesurfer" */ "wavesurfer.js");
  public static readonly MicrophonePlugin = () =>
    import(
      /* webpackChunkName: "wavesurferMicrophonePlugin" */ "wavesurfer.js/dist/plugin/wavesurfer.microphone.js"
    );
}
