import { ApiHandler } from "../ApiHandler";

import { User } from "@/shared/interfaces/User";

export abstract class SmsApi {
    /**
    * Отправить смс код, код действителен 300 с, при запросе отправки нового кода, все старые становятся неактивными
    *
    * @param {number} countryId идентификатор страны. 
    * @param {string} phone номер телефона, на который нужно отправить код. 
    */
    public static async sendCode(countryId: number, phone: string): Promise<{ new_user: number, user: User}> {
        return await ApiHandler.post('sms/send_sms_code', {
            country_id: countryId,
            phone: phone
        });
    }

    /**
    * Проверить смс код и получить токен авторизации. После ввода неверного кода, текущий отправленный код остается активным (300 с). При вводе верного кода, профиль пользователя становится активированным. На этапе тестирования код 11111.
    *
    * @param {number} userId идентификатор пользователя. 
    * @param {string} code код для проверки. 
    */
    public static async checkCode(userId: number, code: string): Promise<{ auth_token: string }> {
        return await ApiHandler.post('sms/check_sms_code', {
            user_id: userId,
            sms_code: code
        });
    }
}