import { ChatsStore } from "../ChatsStore";

export function chatsUpdateMessageStatus(
  chatId: number,
  messageId: number,
  status: number
) {
  const { chatsStore } = ChatsStore.get();

  chatsStore.dispatch(async (state) => {
    const chat = state.chats.filter((s) => s.id == chatId)[0];

    if (chat && chat.last_message && chat.last_message.id == messageId) {
      chat.last_message.status = status;
      chat.status_last_message = status;

      if (status == 2) {
        chat.number_of_unread_messages = 0;
      }
    }

    if (chat == state.selectedChat) {
      for (const messageGroup of state.messageGroups) {
        const message = messageGroup.messages.filter(
          (s) => s.id == messageId
        )[0];

        if (message) {
          message.status = status;
        }
      }
    }
  });
}
