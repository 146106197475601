import { Chat } from "@/shared/interfaces/Chat";
import { MessageGroup } from "@/shared/interfaces/Message";
import { StoreBase } from "@/store/base/StoreBase";
import { chatsLoad } from "./Methods/chatsLoad";

export interface ChatsStoreState {
  isLoading: boolean;

  userId: number;
  chats: Chat[];

  chatsLoadPromise?: Promise<Chat[]>;

  selectedChat: Chat;

  messageGroups: MessageGroup[];
  messagesPage: number;
  areMessagesLoading: boolean;

  areMoreMessagesLoading: boolean;
  areAllMessagesLoaded: boolean;

  isUnreadFilterEnabled: boolean;
}

export class ChatsStore extends StoreBase<ChatsStoreState> {
  public static getNamespace(): string {
    return `chats`;
  }

  constructor() {
    super(ChatsStore.getNamespace());
  }

  public static get(): {
    chatsStore: ChatsStore;
    chatsState: ChatsStoreState;
  } {
    const namespace = this.getNamespace();

    if (!this._hasModule(namespace)) {
      this._register<ChatsStoreState>(namespace, {
        isLoading: true,

        userId: 0,

        chats: [],
        selectedChat: null,

        messageGroups: [],
        messagesPage: 1,
        areMessagesLoading: true,

        areMoreMessagesLoading: false,
        areAllMessagesLoaded: false,

        isUnreadFilterEnabled: false,
      });

      chatsLoad();
    }

    const store = new ChatsStore();

    return {
      chatsStore: store,
      chatsState: store.state,
    };
  }
}
