import dayjs from 'dayjs';

export abstract class TimeFormat {
  public static parse(src: string): dayjs.Dayjs {
    return dayjs
      .utc(src, "HH:mm:ss DD.MM.YYYY", true)
      .local();
  }

  public static fromNow(src: string): string {
    return this.parse(src).utc().fromNow();
  }

  public static time(src: string): string {
    return this.parse(src).format('HH:mm');
  }

  public static dayStart(src: string): string {
    return '00:00:00 ' + this.parse(src).format('DD.MM.YYYY');
  }

  public static todayOrDateShort(src: string) {
    const time = this.parse(src);

    const today = (dayjs as any)();
    const yesterday = (dayjs as any)().add(-1, 'days');

    if (time.isSame(today, 'day')) {
      return 'Сегодня';
    }
    else if (time.isSame(yesterday, 'day')) {
      return 'Вчера';
    }
    else {
      return time.format('DD.MM');
    }
  }

  public static timeOrDay(src: string): string {
    const time = this.parse(src);

    if (time.isSame((dayjs as any)(), 'day')) {
      return time.format('HH:mm');
    }
    else {
      return time.format('dd');
    }
  }

  public static duration(src: string): string {
    if (!src || src.indexOf(':') == -1){
      return '';
    }

    const parts = src.split(':');

    return parts.length == 3
      ? parts[1] + ':' + parts[2]
      : parts[0] + ':' + parts[1];
  }
}