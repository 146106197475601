import { ApiHandler } from "../ApiHandler";

export abstract class SearchApi {
    /**
    * Поиск сообщений и контактов. При поиске по сообщениям chat_ids обязателен. Если указан пустой chat_ids, поиск осуществляется по всем чатам пользователя.
    */
    public static async doSearch(userId: number, searchFor: 'messages' | 'contacts' | 'all', page: number, searchWord: string, chatIds: number[]): Promise<any> {
        return await ApiHandler.post(`user/${userId}/search/${searchFor}/${page}`, {
            search_word: searchWord,
            chat_ids: chatIds
        });
    }
}