import { ChatInfo } from "@/shared/interfaces/ChatInfo";
import { ContactInfo } from "@/shared/interfaces/ContactInfo";
import { Message } from "@/shared/interfaces/Message";
import { User } from "@/shared/interfaces/User";
import { ApiHandler } from "../ApiHandler";

export abstract class MessageApi {
  /** Отправить сообщение пользователю */
  public static async send(
    userId: number,
    chatId: number,
    args: { text: string; content?: any[] }
  ): Promise<Message> {
    const response = await ApiHandler.post(
      `user/${userId}/chats/${chatId}/messages/send_message`,
      args
    );

    return response;
  }

  /** Получить список сообщений */
  public static async get(
    userId: number,
    chatId: number,
    onlyUnread: number,
    page: number
  ): Promise<{
    page: number;
    page_count: number;
    chat_id: number;
    chat: ChatInfo;
    contact: ContactInfo;
    user: User;
    messages: Message[];
  }> {
    const response = await ApiHandler.get(
      `user/${userId}/chats/${chatId}/messages/messages/${onlyUnread}/${page}`
    );

    return response;
  }

  /** Получить список сообщений группового чата */
  public static async getFromGroupChat(
    userId: number,
    chatId: number,
    onlyUnread: number,
    page: number
  ): Promise<{
    page: number;
    page_count: number;
    chat_id: number;
    chat: ChatInfo;
    contact: ContactInfo;
    user: User;
    messages: Message[];
  }> {
    const response = await ApiHandler.get(
      `user/${userId}/chats/groups/${chatId}/messages/messages/${onlyUnread}/${page}`
    );

    response.messages = response.messages.map(
      (messageWithContact: { contact: ContactInfo; message: Message }) => {
        return {
          ...messageWithContact.message,
          contact: messageWithContact.contact,
        };
      }
    );

    return response;
  }

  /** Пометить сообщения в чатах как прочитанные */
  public static async markMessagesAsRead(
    userId: number,
    chatIds: number[]
  ): Promise<void> {
    ApiHandler.post(`user/${userId}/chats/messages/mark_messages_as_read`, {
      chat_ids: chatIds,
    });
  }
}
