import { Cookies } from "@/shared/helpers/Cookies";
import { LazyModules } from "@/shared/LazyModules";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: () => {
      return "login";
    },
    meta: {
      access: ["public"],
    },
  },

  {
    path: "/login",
    name: "login",
    component: LazyModules.LoginView,
    meta: {
      access: ["public"],
    },
  },

  {
    path: "/sms-code",
    name: "smsCode",
    component: LazyModules.SmsCodeView,
    meta: {
      access: ["public"],
    },
  },

  {
    path: "/register",
    name: "register",
    component: LazyModules.RegistrationView,
    meta: {
      access: ["private"],
    },
  },

  {
    path: "/first-contact",
    name: "firstContact",
    component: LazyModules.FirstContactView,
    meta: {
      access: ["private"],
    },
  },
  {
    path: "/chats/:chatId",
    name: "chat",
    component: LazyModules.MessengerView,
    meta: {
      access: ["private"],
    },
  },

  {
    path: "/test-error",
    name: "testError",
    component: LazyModules.TestError,
    meta: {
      access: ["public"],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to: any, from, next) => {
  let accessRules: string[] = to.meta.access;

  if (!accessRules) {
    accessRules = [];
  }

  const isPrivate = accessRules.indexOf("private") != -1;
  const isPublic = accessRules.indexOf("public") != -1;

  const hasCookies = Cookies.get("userId") && Cookies.get("authToken");

  if (isPrivate && isPublic) {
    return;
  } else if (isPrivate && !hasCookies) {
    next({ name: "login" });
    return;
  } else if (isPublic && hasCookies) {
    next("chats/0");
    return;
  } else {
    next();
  }
});

export enum ViewType {
  none = 0,
  chats = 1,
  newMessage = 2,
  editProfile = 3,
  messages = 4,
  search = 5,
  contactInfo = 6,
  editContact = 7,
  folders = 8,
  createFolder = 9,
  addChatsToFolder = 10,
  editFolder = 11,
  createGroup = 12,
  addChatsToGroup = 13,
  editGroup = 14,
  groupMembers = 15,
  groupMembersSearch = 16,
  groupAdmins = 17,
  addAdminsToGroup = 18,
  groupPermissions = 19,
}

export enum ModalType {
  none = 0,
  addContact = 1,
  addFiles = 2,
  privacyDocument = 3,
  personalDataDocument = 4,
  removeContact = 5,
  removeChat = 6,
  removeFolder = 7,
  removeChatFromFolder = 8,
  removeGroupMember = 9,
  removeGroupAdmin = 10,
}

export default router;
