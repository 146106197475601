import { ApiHandler } from "../ApiHandler";

export abstract class DocumentsApi {
    /** Получить политику конфиденциальности */
    public static async getPrivacyPolicy(): Promise<string> {
        const response = await ApiHandler.get(`privacy_policy`);

        return response.privacy_policy;
    }

    /** Получить правила обработки персональных данных */
    public static async getPersonalDataPolicy(): Promise<string> {
        const response = await ApiHandler.get(`personal_data_processing_policy`);

        return response.personal_data_processing_policy;
    }
}