import { PlatformStore } from "../PlatformStore";

export function platformWatchTabActive() {
  const { platformStore } = PlatformStore.get();

  window.onblur = () => {
    platformStore.dispatch(async (state) => {
      state.isTabActive = false;
    });
  };

  window.onfocus = () => {
    platformStore.dispatch(async (state) => {
      state.isTabActive = true;
    });
  };
}
