import { Cookies } from "@/shared/helpers/Cookies";
import { ContactsStore } from "../ContactsStore";
import { Api } from "@/shared/api/Api";
import { Contact } from "@/shared/interfaces/Contact";

export async function contactsRefresh(): Promise<Contact[]> {
  const { contactsStore } = ContactsStore.get();

  if (!Cookies.get("userId")) {
    contactsStore.dispatch(async (state) => {
      state.userId = 0;
      state.contacts = [];
    });
  } else {
    const userId = +Cookies.get("userId");
    const contacts = await Api.contacts.get(userId);

    contactsStore.dispatch(async (state) => {
      state.userId = userId;
      state.contacts = contacts;
    });
  }

  contactsStore.dispatch(async (state) => {
    state.isLoading = false;
  });

  return contactsStore.state.contacts;
}
