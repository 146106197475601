import { StoreBase } from "@/store/base/StoreBase";
import { platformGet } from "./Methods/platformGet";
import { platformRefreshSize } from "./Methods/platformRefreshSize";
import { platformWatchTabActive } from "./Methods/platformWatchTabActive";
import { platformWatchSize } from "./Methods/platformWatchSize";

export interface PlatformStoreState {
  platform: "mobile" | "tablet" | "desktop" | undefined;
  isTabActive: boolean;
}

export class PlatformStore extends StoreBase<PlatformStoreState> {
  public static getNamespace(): string {
    return `platform`;
  }

  constructor() {
    super(PlatformStore.getNamespace());
  }

  public static get(): {
    platformStore: PlatformStore;
    platformState: PlatformStoreState;
  } {
    const namespace = this.getNamespace();

    if (!this._hasModule(namespace)) {
      this._register<PlatformStoreState>(namespace, {
        platform: undefined,
        isTabActive: true,
      });

      platformWatchSize();
      platformWatchTabActive();
    }

    const store = new PlatformStore();

    return {
      platformStore: store,
      platformState: store.state,
    };
  }
}
