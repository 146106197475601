import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Sentry from "@sentry/vue";

import * as dayjs from "dayjs";

import "dayjs/locale/ru";

import * as utc from "dayjs/plugin/utc";
import * as relativeTime from "dayjs/plugin/relativeTime";
import * as customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(relativeTime as any);
dayjs.extend(customParseFormat as any);
dayjs.extend(utc as any);
dayjs.locale("ru");

const app = createApp(App);

app.use(store);
app.use(router);

app.directive("focus", {
  mounted(el) {
    el.focus();
  },
});

Sentry.init({
  dsn: "https://88c00e113815f4bedc640f795828d8fd@sentry.dtdesk.ru/4",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  app: app,
  environment: "production",
  attachProps: true,
  logErrors: true,
  trackComponents: true,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/dtchat.dtdesk.ru\/api/,
    /^wss:\/\/dtchat.dtdesk.ru\/ws/,
    /^https:\/\/dtchatm.d-tech.site\/api/,
    /^wss:\/\/dtchatm.d-tech.site\/ws/,
  ], //, /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount("#app");
