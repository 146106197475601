import { ChatsStore } from "../ChatsStore";

export function chatsSetUserOnline(
  userId: number,
  isOnline: number,
  lastOnlineDate: string
) {
  const { chatsStore } = ChatsStore.get();

  chatsStore.dispatch(async (state) => {
    const chat = state.chats.filter(
      (s) => !s.group_info && s.user.id == userId
    )[0];

    if (chat) {
      chat.user.is_online = isOnline;
      chat.user.dt_last_online = lastOnlineDate;
    }
  });
}
