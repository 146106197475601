import { Chat } from "@/shared/interfaces/Chat";
import { ChatsStore } from "../ChatsStore";
import { chatsRefresh } from "./chatsRefresh";

export async function chatsLoad(): Promise<Chat[]> {
  const { chatsStore } = ChatsStore.get();

  const loadPromise = chatsRefresh();

  chatsStore.dispatch(async (state) => {
    state.chatsLoadPromise = loadPromise;
  });

  const chats = await loadPromise;

  chatsStore.dispatch(async (state) => {
    state.chatsLoadPromise = undefined;
  });

  return chats;
}
