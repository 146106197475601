import { Chat } from "@/shared/interfaces/Chat";
import { chatsGetPaged } from "./chatsGetPaged";

export async function chatsGetAll(): Promise<Chat[]> {
  let chatsResponse = await chatsGetPaged(0, 1);

  const chats = chatsResponse.chats;

  if (chatsResponse.page_count > 1) {
    for (let i = 2; i < chatsResponse.page_count + 1; i++) {
      chatsResponse = await chatsGetPaged(0, i);

      for (const chat of chatsResponse.chats) {
        chats.push(chat);
      }
    }
  }

  return chats;
}
