import { foldersRefresh } from "./foldersRefresh";
import { FoldersStore } from "../FoldersStore";
import { Folder } from "@/shared/interfaces/Folder";

export async function foldersLoad(): Promise<Folder[]> {
  const { foldersStore } = FoldersStore.get();

  const loadPromise = foldersRefresh();

  foldersStore.dispatch(async (state) => {
    state.foldersLoadPromise = loadPromise;
  });

  const folders = await loadPromise;

  foldersStore.dispatch(async (state) => {
    state.foldersLoadPromise = undefined;
  });

  return folders;
}
