import { MessageMedia } from "@/shared/interfaces/MessageMedia";
import { ApiHandler } from "../ApiHandler";

export abstract class MediasApi {
  /** Получить список медиа чата */
  public static async get(
    userId: number,
    chatId: number,
    filetype: "photo" | "video" | "audio" | "document",
    page: number
  ): Promise<{
    page: number;
    page_count: number;
    medias: MessageMedia[];
  }> {
    const response = await ApiHandler.get(
      `user/${userId}/chats/${chatId}/0/medias/${filetype}/${page}`
    );

    return response;
  }
}
