import { StoreBase } from "@/store/base/StoreBase";
import { foldersLoad } from "./Methods/foldersLoad";
import { Folder } from "@/shared/interfaces/Folder";

export interface FoldersStoreState {
  isLoading: boolean;

  userId: number;
  folders: Folder[];

  foldersLoadPromise?: Promise<Folder[]>;
  selectedFolder?: Folder;
}

export class FoldersStore extends StoreBase<FoldersStoreState> {
  public static getNamespace(): string {
    return `folders`;
  }

  constructor() {
    super(FoldersStore.getNamespace());
  }

  public static get(): {
    foldersStore: FoldersStore;
    foldersState: FoldersStoreState;
  } {
    const namespace = this.getNamespace();

    if (!this._hasModule(namespace)) {
      this._register<FoldersStoreState>(namespace, {
        isLoading: true,

        userId: 0,
        folders: [],
      });

      foldersLoad();
    }

    const store = new FoldersStore();

    return {
      foldersStore: store,
      foldersState: store.state,
    };
  }
}
