import { PrincipalStore } from "../PrincipalStore";
import { User } from "@/shared/interfaces/User";
import { principalRefresh } from "./principalRefresh";

export async function principalLoad(): Promise<User> {
  const { principalStore } = PrincipalStore.get();

  const loadPromise = principalRefresh();

  principalStore.dispatch(async (state) => {
    state.userLoadPromise = loadPromise;
  });

  const user = await loadPromise;

  principalStore.dispatch(async (state) => {
    state.userLoadPromise = undefined;
  });

  return user;
}
