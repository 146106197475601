import { Cookies } from "@/shared/helpers/Cookies";
import { ChatsStore } from "../ChatsStore";
import { chatsGetAll } from "./chatsGetAll";
import { Chat } from "@/shared/interfaces/Chat";

export async function chatsRefresh(): Promise<Chat[]> {
  const { chatsStore } = ChatsStore.get();
  if (!Cookies.get("userId")) {
    chatsStore.dispatch(async (state) => {
      state.userId = 0;
      state.chats = [];
    });
  } else {
    chatsStore.dispatch(async (state) => {
      state.userId = +Cookies.get("userId");
    });

    const chats = await chatsGetAll();

    chatsStore.dispatch(async (state) => {
      state.chats = chats;
    });
  }

  chatsStore.dispatch(async (state) => {
    state.isLoading = false;
  });

  return chatsStore.state.chats;
}
