import { Chat } from "@/shared/interfaces/Chat";
import { contactsRefresh } from "./contactsRefresh";
import { ContactsStore } from "../ContactsStore";
import { Contact } from "@/shared/interfaces/Contact";

export async function contactsLoad(): Promise<Contact[]> {
  const { contactsStore } = ContactsStore.get();

  const loadPromise = contactsRefresh();

  contactsStore.dispatch(async (state) => {
    state.contactsLoadPromise = loadPromise;
  });

  const contacts = await loadPromise;

  contactsStore.dispatch(async (state) => {
    state.contactsLoadPromise = undefined;
  });

  return contacts;
}
