import { User } from "@/shared/interfaces/User";
import { StoreBase } from "@/store/base/StoreBase";
import { principalLoad } from "./Methods/principalLoad";

export interface PrincipalStoreState {
  isLoading: boolean;

  userId: number;
  user: User;

  userLoadPromise?: Promise<User>;
}

export class PrincipalStore extends StoreBase<PrincipalStoreState> {
  public static getNamespace(): string {
    return `principal`;
  }

  constructor() {
    super(PrincipalStore.getNamespace());
  }

  public static get(): {
    principalStore: PrincipalStore;
    principalState: PrincipalStoreState;
  } {
    const namespace = this.getNamespace();

    if (!this._hasModule(namespace)) {
      this._register<PrincipalStoreState>(namespace, {
        isLoading: true,

        userId: 0,
        user: null,
      });

      principalLoad();
    }

    const store = new PrincipalStore();

    return {
      principalStore: store,
      principalState: store.state,
    };
  }
}
