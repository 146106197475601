
import { defineComponent } from "vue-demi";
import { ModalType, ViewType } from "@/router";
import { ModalStore } from "@/store/modules/Modals/ModalStore";
import { modalSetActive } from "@/store/modules/Modals/Methods/modalSetActive";
import { SidebarStore } from "@/store/modules/Sidebar/SidebarStore";
import { sidebarSetLeftView } from "@/store/modules/Sidebar/Methods/sidebarSetLeftView";
import { sidebarPushLeftView } from "@/store/modules/Sidebar/Methods/sidebarPushLeftView";
import { sidebarPushRightView } from "@/store/modules/Sidebar/Methods/sidebarPushRightView";
import { sidebarSetRightView } from "@/store/modules/Sidebar/Methods/sidebarSetRightView";
import { PlatformStore } from "@/store/modules/Platform/PlatformStore";
import { ChatsStore } from "@/store/modules/Chats/ChatsStore";

export default defineComponent({
  components: {},
  watch: {
    "$route.query.m"(modal: string) {
      const modalType = ModalType[modal];

      if (this.modalState.activeModal != modalType) {
        modalSetActive(modalType);
      }
    },
    "$route.query.left"() {
      this.refreshLeftView();
    },
    "$route.query.right"() {
      this.refreshRightView();
    },
    "$store.state.platform.platform"() {
      this.refreshLeftView();
      this.refreshRightView();
    },
    "$store.state.chats.selectedChat"() {
      this.refreshLeftView();
      this.refreshRightView();
    },
  },
  methods: {
    getValidLeftViews(): ViewType[] {
      if (this.platformState.platform == "mobile") {
        return window.location.pathname.indexOf("/chats/0") == -1
          ? [
              ViewType.messages,
              ViewType.contactInfo,
              ViewType.editContact,
              ViewType.search,
              ViewType.createGroup,
              ViewType.editGroup,
              ViewType.groupMembers,
              ViewType.groupMembersSearch,
              ViewType.groupAdmins,
              ViewType.addChatsToGroup,
              ViewType.addAdminsToGroup,
              ViewType.groupPermissions,
              ViewType.folders,
              ViewType.createFolder,
              ViewType.editFolder,
              ViewType.addChatsToFolder,
            ]
          : [
              ViewType.chats,
              ViewType.newMessage,
              ViewType.editProfile,
              ViewType.createGroup,
              ViewType.editGroup,
              ViewType.groupMembers,
              ViewType.groupMembersSearch,
              ViewType.groupAdmins,
              ViewType.addChatsToGroup,
              ViewType.addAdminsToGroup,
              ViewType.groupPermissions,
              ViewType.folders,
              ViewType.createFolder,
              ViewType.editFolder,
              ViewType.addChatsToFolder,
            ];
      } else {
        return [
          ViewType.chats,
          ViewType.newMessage,
          ViewType.editProfile,
          ViewType.createGroup,
          ViewType.editGroup,
          ViewType.groupMembers,
          ViewType.groupMembersSearch,
          ViewType.groupAdmins,
          ViewType.addChatsToGroup,
          ViewType.addAdminsToGroup,
          ViewType.groupPermissions,
          ViewType.folders,
          ViewType.createFolder,
          ViewType.editFolder,
          ViewType.addChatsToFolder,
        ];
      }
    },

    getValidRightViews(): ViewType[] {
      if (this.platformState.platform == "desktop") {
        return this.chatsState.selectedChat
          ? [
              ViewType.none,
              ViewType.search,
              ViewType.contactInfo,
              ViewType.editContact,
              ViewType.editGroup,
              ViewType.groupMembers,
              ViewType.groupMembersSearch,
              ViewType.groupAdmins,
              ViewType.addChatsToGroup,
              ViewType.addAdminsToGroup,
              ViewType.groupPermissions,
            ]
          : [];
      } else if (this.platformState.platform == "tablet") {
        return this.chatsState.selectedChat
          ? [
              ViewType.messages,
              ViewType.search,
              ViewType.contactInfo,
              ViewType.editContact,
              ViewType.editGroup,
              ViewType.groupMembers,
              ViewType.groupMembersSearch,
              ViewType.groupAdmins,
              ViewType.addChatsToGroup,
              ViewType.addAdminsToGroup,
              ViewType.groupPermissions,
            ]
          : [];
      } else {
        return [];
      }
    },

    refreshLeftView() {
      const viewFromQuery = this.$route.query.left as string;

      let view =
        viewFromQuery && viewFromQuery.length
          ? ViewType[viewFromQuery]
          : ViewType.none;

      if (view == ViewType.none) {
        view = this.sidebarState.leftView;
      }

      const validViews = this.getValidLeftViews();

      if (validViews.indexOf(view) == -1) {
        view = validViews[0];
      }

      if (this.sidebarState.leftView != view) {
        sidebarSetLeftView(view);
        sidebarPushLeftView(view);
      }
    },

    refreshRightView() {
      const viewFromQuery = this.$route.query.right as string;

      let view =
        viewFromQuery && viewFromQuery.length
          ? ViewType[viewFromQuery]
          : ViewType.none;

      if (view != ViewType.none && !view) {
        view = this.sidebarState.rightView;
      }

      const validViews = this.getValidRightViews();

      if (validViews.indexOf(view) == -1) {
        view = validViews[0];
      }

      if (this.sidebarState.rightView != view) {
        sidebarSetRightView(view);
        sidebarPushRightView(view);
      }
    },
  },
  created() {
    this.refreshLeftView();
    this.refreshRightView();
  },
  setup() {
    const { modalState } = ModalStore.get();
    const { sidebarState } = SidebarStore.get();
    const { platformState } = PlatformStore.get();
    const { chatsState } = ChatsStore.get();

    return {
      platformState,
      sidebarState,
      modalState,
      chatsState,
    };
  },
});
