import { Contact } from "@/shared/interfaces/Contact";
import { StoreBase } from "@/store/base/StoreBase";
import { contactsLoad } from "./Methods/contactsLoad";

export interface ContactsStoreState {
  isLoading: boolean;

  userId: number;
  contacts: Contact[];

  contactsLoadPromise?: Promise<Contact[]>;
}

export class ContactsStore extends StoreBase<ContactsStoreState> {
  public static getNamespace(): string {
    return `contacts`;
  }

  constructor() {
    super(ContactsStore.getNamespace());
  }

  public static get(): {
    contactsStore: ContactsStore;
    contactsState: ContactsStoreState;
  } {
    const namespace = this.getNamespace();

    if (!this._hasModule(namespace)) {
      this._register<ContactsStoreState>(namespace, {
        isLoading: true,

        userId: 0,
        contacts: [],
      });

      contactsLoad();
    }

    const store = new ContactsStore();

    return {
      contactsStore: store,
      contactsState: store.state,
    };
  }
}
