import axios from "axios";
import { Cookies } from "@/shared/helpers/Cookies";

export abstract class ApiHandler {
  public static get(path: string): Promise<any> {
    return this.request("GET", path, null);
  }

  public static post(path: string, parameters: any): Promise<any> {
    return this.request("POST", path, parameters);
  }

  public static delete(path: string, parameters: any): Promise<any> {
    return this.request("DELETE", path, parameters);
  }

  public static request(
    method: "GET" | "POST" | "DELETE",
    path: string,
    parameters: any
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const headers: any = {
        "Content-Type": "application/json",
      };

      let hasFile = false;

      if (method != "GET" && parameters) {
        for (const key of Object.keys(parameters)) {
          const value = parameters[key];

          if ("File" in window && value instanceof File) {
            hasFile = true;
          }
        }
      }

      let data: any = null;

      if (hasFile) {
        const formData = new FormData();

        for (const key of Object.keys(parameters)) {
          const value = parameters[key];

          if (value !== null && value !== undefined) {
            formData.append(key, value);
          }
        }

        data = formData;
        headers["Content-Type"] = "application/x-www-form-urlencoded";
      } else if (method != "GET") {
        data = {};

        for (const key of Object.keys(parameters)) {
          const value = parameters[key];

          if (value !== null && value !== undefined) {
            data[key] = value;
          }
        }
      }

      if (Cookies.get("authToken")) {
        headers["Authorization"] = "Bearer " + Cookies.get("authToken");
      }

      axios
        .request({
          method: method,
          url: `https://dtchat.dtdesk.ru/api/${path}`,
          data: data,
        })
        .then((response) => {
          const responseObj: any = response.data;

          if (!responseObj.message) {
            resolve(responseObj);
          } else {
            reject(responseObj);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
