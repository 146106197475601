import { ApiHandler } from "../ApiHandler";

import { UpdateProfileRequest, User } from "@/shared/interfaces/User";

export abstract class UserApi {
    /**
    * Получить данные профиля пользователя
    *
    * @param {number} userId идентификатор пользователя. 
    */
    public static async get(userId: number): Promise<User> {
        return await ApiHandler.get(`user/${userId}/profile/profile`);
    }

    /**
    * Обновить данные профиля пользователя.
    */
    public static async updateProfile(args: UpdateProfileRequest): Promise<void> {
        await ApiHandler.post(`user/${args.userId}/profile/update_profile`, {
            name: args.firstname,
            surname: args.lastname,
            username: args.username,
            avatar: args.avatar,
            about_me: args.about,
            is_phone_visible: args.isPhoneVisible ? 1 : 0,
            is_notifications_active: args.isNotificationsEnabled ? 1 : 0
        });
    }


}