import { User } from "@/shared/interfaces/User";
import { ContactsStore } from "../ContactsStore";

export async function contactsUpdateProfile(userId: number, user: User) {
  const { contactsStore } = ContactsStore.get();

  contactsStore.dispatch(async (state) => {
    const contact = state.contacts.filter((s) => s.user.id == userId)[0];

    if (contact) {
      contact.user.about_me = user.about_me;
      contact.user.avatar = user.avatar;
      contact.user.name = user.name;
      contact.user.surname = user.surname;
    }
  });
}
