
import "./App.css";

import WebSocketManager from "@/shared/managers/WebSocketManager.vue";
import RouteManager from "@/shared/managers/RouteManager.vue";

import { LazyModules } from "./shared/LazyModules";
import TabActiveManager from "./shared/managers/TabActiveManager.vue";

export default {
  components: {
    WebSocketManager,
    RouteManager,
    TabActiveManager,
  },
  mounted() {
    setTimeout(() => {
      for (const key in LazyModules) {
        LazyModules[key]();
      }
    }, 500);
  },
};
