import { PlatformStore } from "../PlatformStore";
import { platformGet } from "./platformGet";

export function platformRefreshSize() {
  const platform = platformGet();
  const { platformState, platformStore } = PlatformStore.get();

  if (platformState.platform != platform) {
    if (platform == "mobile") {
      if (!document.body.classList.contains("body--mobile")) {
        document.body.classList.add("body--mobile");
      }
    } else {
      if (document.body.classList.contains("body--mobile")) {
        document.body.classList.remove("body--mobile");
      }
    }

    if (platform == "tablet") {
      if (!document.body.classList.contains("body--tablet")) {
        document.body.classList.add("body--tablet");
      }
    } else {
      if (document.body.classList.contains("body--tablet")) {
        document.body.classList.remove("body--tablet");
      }
    }

    if (platform == "desktop") {
      if (!document.body.classList.contains("body--desktop")) {
        document.body.classList.add("body--desktop");
      }
    } else {
      if (document.body.classList.contains("body--desktop")) {
        document.body.classList.remove("body--desktop");
      }
    }

    platformStore.dispatch(async (state) => {
      state.platform = platform;
    });
  }
}
