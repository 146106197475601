import { StoreBase } from "@/store/base/StoreBase";
import { ModalType } from "@/router";

export interface ModalStoreState {
  activeModal: ModalType;
}

export class ModalStore extends StoreBase<ModalStoreState> {
  public static getNamespace(): string {
    return `modals`;
  }

  constructor() {
    super(ModalStore.getNamespace());
  }

  public static get(): {
    modalStore: ModalStore;
    modalState: ModalStoreState;
  } {
    const namespace = this.getNamespace();

    if (!this._hasModule(namespace)) {
      this._register<ModalStoreState>(namespace, {
        activeModal: ModalType.none,
      });
    }

    const store = new ModalStore();

    return {
      modalStore: store,
      modalState: store.state,
    };
  }
}
