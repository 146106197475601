import { ChatsStore } from "../ChatsStore";
import { chatsRefresh } from "./chatsRefresh";
import { Message } from "@/shared/interfaces/Message";
import { User } from "@/shared/interfaces/User";
import { TimeFormat } from "@/shared/helpers/TimeFormat";

export async function chatsAddMessageToChat(
  chatId: number,
  message: Message,
  sender: User = null
) {
  const { chatsStore, chatsState } = ChatsStore.get();

  if (!chatsState.chats.filter((s) => s.id == chatId).length) {
    await chatsRefresh();
    return;
  }

  chatsStore.dispatch(async (state) => {
    const chat = state.chats.filter((s) => s.id == chatId)[0];

    chat.content_last_message = message.content;
    chat.dt_departure_last_message = message.dt_departure;
    chat.last_message = message;

    const isSelectedChat =
      state.selectedChat && chat.id == state.selectedChat.id;

    if (!isSelectedChat && sender && sender.id != state.userId) {
      chat.number_of_unread_messages++;
    }

    if (isSelectedChat && !state.areMessagesLoading) {
      let messageGroup = state.messageGroups.filter(
        (s) => s.date == TimeFormat.dayStart(message.dt_departure)
      )[0];

      if (!messageGroup) {
        messageGroup = {
          messages: [],
          date: TimeFormat.dayStart(message.dt_departure),
        };

        state.messageGroups = [messageGroup, ...state.messageGroups];
      }

      if (!messageGroup.messages.filter((s) => s.id == message.id).length) {
        messageGroup.messages = [message, ...messageGroup.messages];
      }
    }

    state.chats = [chat, ...state.chats.filter((s) => s.id != chatId)];
  });
}
