import { PrincipalStore } from "../PrincipalStore";
import { User } from "@/shared/interfaces/User";
import { Cookies } from "@/shared/helpers/Cookies";
import { principalGetProfile } from "./principalGetProfile";

export async function principalRefresh(): Promise<User> {
  const { principalStore } = PrincipalStore.get();

  if (!Cookies.get("userId")) {
    principalStore.dispatch(async (state) => {
      state.userId = 0;
      state.user = null;
    });
  } else {
    const userId = +Cookies.get("userId");
    const user = await principalGetProfile(userId);

    principalStore.dispatch(async (state) => {
      state.userId = +Cookies.get("userId");
      state.user = user;
    });
  }

  principalStore.dispatch(async (state) => {
    state.isLoading = false;
  });

  return principalStore.state.user;
}
