import { Chat } from "@/shared/interfaces/Chat";
import { ApiHandler } from "../ApiHandler";

export abstract class ChatsApi {
  /**
   * Удалить чат пользователя
   */
  public static async delete(userId: number, chatId: number): Promise<void> {
    const response = await ApiHandler.delete(
      `user/${userId}/chats/delete_chats`,
      {
        chat_ids: [chatId],
      }
    );

    return response;
  }
}
