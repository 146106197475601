
import { defineComponent } from "vue-demi";
import { ChatsStore } from "@/store/modules/Chats/ChatsStore";
import { chatsMarkMessagesAsRead } from "@/store/modules/Chats/Methods/chatsMarkMessagesAsRead";

export default defineComponent({
  mounted() {
    window.onfocus = () => {
      if (this.chatsState.selectedChat) {
        const minChatScrollPosition = -700;
        let chatScrollPosition = minChatScrollPosition - 1;

        try {
          chatScrollPosition =
            document.querySelector(".cMessageGroups").scrollTop;
        } catch (e) {}

        if (chatScrollPosition > minChatScrollPosition) {
          chatsMarkMessagesAsRead([this.chatsState.selectedChat.id]);
        }
      }
    };
  },
  setup() {
    const { chatsState } = ChatsStore.get();

    return {
      chatsState,
    };
  },
});
