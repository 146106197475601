import { Contact } from "@/shared/interfaces/Contact";
import { ApiHandler } from "../ApiHandler";

export abstract class ContactsApi {
    /**
    * Получить список контактов пользователя. Объект класса Contact необходим только для персонализации отображения имени и фамилии другого пользователя у текущего пользователя, для всех взаимодействий необходимо использовать user.id
    */
    public static async get(userId: number): Promise<Contact[]> {
        const response = await ApiHandler.get(`user/${userId}/contacts/contacts`);

        return response;
    }

    /**
    * Добавление новых контактов пользователя. Контакт добавляется только в том случае, если пользователь существует
    */
    public static async create(userId: number, contacts: { firstname: string, lastname: string, phone: string }[]): Promise<{ value_created_contacts: number, value_existing_contacts: number, value_non_existent_phonebook_contacts: number, created_contacts: Contact[], existing_contacts: Contact[] }> {
        const response = await ApiHandler.post(`user/${userId}/contacts/create_contacts`, {
            phonebook_contacts: contacts.map((contact) => {
                return {
                    name: contact.firstname,
                    surname: contact.lastname,
                    phone: contact.phone
                };
            })
        });

        return response;
    }

    /**
   * Обновить контакт пользователя
   */
    public static async edit(userId: number, contactId: number, firstname: string, lastname: string): Promise<any> {
        const response = await ApiHandler.post(`user/${userId}/contacts/update_contact/${contactId}`, {
            name: firstname,
            surname: lastname
        });

        return response;
    }

    /**
    * Удалить контакт пользователя
    */
    public static async delete(userId: number, contactId: number): Promise<void> {
        const response = await ApiHandler.delete(`user/${userId}/contacts/delete_contact`, {
            contact_id: contactId
        });

        return response;
    }
}