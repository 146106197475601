import { ChatsStore } from "../ChatsStore";
import { Api } from "@/shared/api/Api";

export function chatsMarkMessagesAsRead(chatIds: number[]) {
  const { chatsStore, chatsState } = ChatsStore.get();

  chatsStore.dispatch(async (state) => {
    for (const chat of state.chats) {
      if (chatIds.indexOf(chat.id) != -1) {
        chat.number_of_unread_messages = 0;
      }
    }
  });

  Api.messages.markMessagesAsRead(chatsState.userId, chatIds);
}
