import { ApiHandler } from "../ApiHandler";

export abstract class UsernameApi {
    /**
    * Сгенерировать username
    */
    public static async generate(args: { firstname: string, lastname: string }): Promise<{ username: string, is_username_free: number, free_usernames: string }> {
        const response = await ApiHandler.post('username/generate_username', {
            name: args.firstname,
            surname: args.lastname
        });

        return response;
    }

    /**
    * Проверить username, не занят ли
    */
    public static async check(args: { firstname: string, lastname: string, username: string }): Promise<{ is_username_free: number, free_usernames: string }> {
        const response = await ApiHandler.post('username/check_username', {
            name: args.firstname,
            surname: args.lastname,
            username: args.username
        });

        return response;
    }
}