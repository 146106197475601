import { routerParseUrl } from "./routerParseUrl";

export function routerAddQueryParam(url: string, query: any): string {
  if (!url) {
    return url;
  }

  const parsedUrl = routerParseUrl(url);

  for (const key of Object.keys(query)) {
    const value = query[key];

    if (!value && parsedUrl.searchParams.get(key)) {
      parsedUrl.searchParams.delete(key);
    } else if (value) {
      parsedUrl.searchParams.set(key, value);
    }
  }

  let resultUrl = parsedUrl.toString();

  if (url.startsWith("/")) {
    resultUrl = resultUrl.replace(parsedUrl.origin, "");
  }

  return resultUrl;
}
