export function routerParseUrl(url: string): URL {
  if (!url) {
    return null;
  }

  if (url.indexOf("/") == 0) {
    url = window.location.origin + url;
  }

  return new URL(url);
}
