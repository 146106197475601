import { ViewType } from "@/router";

import { SidebarStore } from "../SidebarStore";

export function sidebarSetRightView(view: ViewType): void {
  const { sidebarStore } = SidebarStore.get();

  sidebarStore.dispatch(async (state) => {
    state.rightView = view;
  });
}
