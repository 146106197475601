import { ChatsStore } from "../ChatsStore";
import { User } from "@/shared/interfaces/User";

export function chatsUpdateUserProfile(user: User) {
  const { chatsStore } = ChatsStore.get();

  chatsStore.dispatch(async (state) => {
    const chat = state.chats.filter((s) => s.user.id == user.id)[0];

    if (chat) {
      chat.user.about_me = user.about_me;
      chat.user.avatar = user.avatar;
      chat.user.name = user.name;
      chat.user.surname = user.surname;
    }
  });
}
