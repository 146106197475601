import { ModalType } from "@/router";
import { ModalStore } from "../ModalStore";

export function modalSetActive(modal: ModalType): void {
  const { modalStore } = ModalStore.get();

  modalStore.dispatch(async (state) => {
    state.activeModal = modal;
  });
}
